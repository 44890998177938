<template>
  <b-modal
    name="editor-markdown"
    id="editor-markdown"
    size="xl"
    title=""
    @shown="openModal"
    @hidden="reset"
    hide-footer
    hide-header
  >
    <h5 class="modal-header">Formate aqui o seu texto</h5>  
    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <section v-else>
      <v-md-editor
        ref="editor"
        v-model="text"
        :toolbar="toolbar"
        left-toolbar="undo redo clear h h1 h2 h3 h4 h5 h6 italic strikethrough quote ul ol table hr link image2 imageLink uploadImage | code save preview syncScroll "
        :placeholder="$t('editor_markdown.text_340')"
        height="500px"
      ></v-md-editor>
    </section>   
    <div class="d-flex justify-content-end mt-3">
      <BaseButton :disabled="loading" class="btn-markdown" variant="black" @click="sendTextEdition">
        {{ $t('editor_markdown.text_1893') }}
      </BaseButton>
    </div>
    <ModalUpload @updateIdMidiaUrl="handleIdMidiaUrlUpdate"/>
  </b-modal>
</template>

<script>
import ModalUpload from '@/components/ModalUpload.vue';
export default {
  components: {
    ModalUpload
  },
  name: "EditorMarkDown",
  data() {
    this.toolbar = {
      image2: {
        title: 'panel',
        icon: 'v-md-icon-img',
        menus: {
          mode: 'panel',
          itemWidth: '150px',
          rowNum: 1,          
          items: [
            {
              name: 'Inserir link',
              text: 'Inserir link',
              action: () => {              
                this.urlLink = '';                 
                this.editorLinkImg();                
              },             
            },        
            {
              name: 'Biblioteca de mídias',
              text: 'Biblioteca de mídias',
              action: () => {                          
                this.showModalUpload();               
              },
            },                  
          ],
        },
      },   
    };
    return {
      urlLink: '',    
      loading: false,
      text: "",
    };
  },
  props: {
    text_parent: {
      type: String,
      default: "",
    },
    from: {
      type: String,
    },
  },
  methods: {
    sendTextEdition() {
      if (this.from == "edit") {
        this.$emit("updated", this.text)
      } else if (this.from == "new") {
        this.$emit("created", this.text)
      }
      this.$bvModal.hide("editor-markdown");
    },
    reset() {
      this.text = "";
    },
    openModal() {
      this.text = this.text_parent;
    },
    handleIdMidiaUrlUpdate(newIdMidiaUrl) {
      this.urlLink = newIdMidiaUrl;     
      this.editorLinkImg();      
    },
    editorLinkImg() {  
      const editorInstance = this.$refs.editor;    
      if (editorInstance) {
      editorInstance.insert((selected) => {    
        const textContent = `![Description](${this.urlLink ? this.urlLink : 'https://'})`;      
        return {
          text: textContent,
          selected: selected || this.urlLink, 
        };
      });
      } 
    }, 
    showModalUpload() {    
      this.$root.$emit('bv::show::modal', 'modal-upload', '#btnShow');
    },
  },
};
</script>

<style scoped>
.v-md-textarea-editor textarea{
  height: 100% !important;
  border: inherit !important;
  width: 100% !important;
  box-shadow: none !important;
}

.btn-top {
  position: absolute;
  top: -65px;
  right: 0;
}

.btn-markdown{
  background: var(--maincolor);
  border: 1px solid var(--maincolor);
}

.modal-header{
  color: var(--fontcolor2) !important;
}

.v-md-editor__toolbar{
  background: var(--maincolor) !important;
}
</style>

